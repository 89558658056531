let el = {}

const settings = {
  selectors: {
    body: 'body',
    hideMenusBtn: '.js-hide-menus-btn',
    toggleSectionsMenuBtn: '[data-toggle="sections-menu"]',
    togglePersonalMenuBtn: '[data-toggle="personal-menu"]',
    appendAround: '.js-append-around',
    masonry: '.js-masonry',
    masonryItem: '.js-masonry-item',
    typeahead: '.typeahead'
  },
  classnames: {
    sectionsMenuIsOpen: 'sections-menu-is-open',
    personalMenuIsOpen: 'personal-menu-is-open'
  }
}

function init() {
  cacheElements()
  bindEvents()
}

function cacheElements () {
  el.body = $(settings.selectors.body)
  el.hideMenusBtn = $(settings.selectors.hideMenusBtn)
  el.toggleSectionsMenuBtn = $(settings.selectors.toggleSectionsMenuBtn)
  el.togglePersonalMenuBtn = $(settings.selectors.togglePersonalMenuBtn)
  el.appendAround = $(settings.selectors.appendAround)
  el.masonry = $(settings.selectors.masonry)
}

function bindEvents () {
  if ($.fn.tooltip) {
    $('[data-toggle="tooltip"]').tooltip()
  }

  if ($.fn.appendAround) {
    el.appendAround.appendAround()
  }
  if ($.fn.masonry) {
    el.masonry.masonry({
      itemSelector: settings.selectors.masonryItem,
      columnWidth: settings.selectors.masonryItem,
      percentPosition: true
    })
  }

  if ($.fn.matchHeight) {
    $.fn.matchHeight._update()
  }

  // Attach event to document — then we can rerender page in e.g. React
  // without loosing the bind event
  $(document).off('click', settings.selectors.hideMenusBtn, hideMenus)
  $(document).on('click', settings.selectors.hideMenusBtn, hideMenus)

  $(document).off('click', settings.selectors.toggleSectionsMenuBtn, toggleSectionsMenu)
  $(document).on('click', settings.selectors.toggleSectionsMenuBtn, toggleSectionsMenu)

  $(document).off('click', settings.selectors.togglePersonalMenuBtn, togglePersonalMenu)
  $(document).on('click', settings.selectors.togglePersonalMenuBtn, togglePersonalMenu)
}

function hideMenus() {
  el.body.removeClass(settings.classnames.sectionsMenuIsOpen + ' ' + settings.classnames.personalMenuIsOpen)
}

function toggleSectionsMenu() {
  el.body.toggleClass(settings.classnames.sectionsMenuIsOpen)
}

function togglePersonalMenu() {
  el.body.toggleClass(settings.classnames.personalMenuIsOpen)
}

$(document).on('ready turbolinks:load', function() {
  init()
})
