import './stylesheets/devise.scss'

//==============================================
// Vendor
//==============================================

import 'jquery'
import 'jquery-ujs'

import Tether from 'tether'
global.Tether = Tether
import 'bootstrap'

//==============================================
// App
//==============================================

import './javascripts/layout'
import './javascripts/devise/sessions.coffee'
