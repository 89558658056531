window.Sessions ?= {}

self = null
window.Sessions.New =
  init: ->
    self = this
    $ ->
      $('#forgot-password-link').on 'click', (e) ->
        e.preventDefault()
        email = $('#user_email').val()
        url = "#{$(this).attr('href')}?email=#{email}"
        window.location = url
